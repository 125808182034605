import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { ehiTheme } from '@ehi/ui';
import { useAppSelector } from 'redux/hooks';
import { useVehicleContentQuery } from 'services/vehicleContent/vehicleContentQueries';
import { VehicleList } from 'components/flexFlow/vehicle/VehicleList';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { VehicleSearchContainer } from 'components/flexFlow/vehicle/VehiclePage.styles';
import { VehicleSearchForm } from 'components/flexFlow/vehicle/vehicleSearch/VehicleSearchForm';
import { VehicleInfo } from 'components/flexFlow/vehicle/VehicleInfo';
import { selectHasLocationData, selectIsModifyFlow, selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';
import { useVehicleRates } from 'services/booking/useVehicleRates';
import { FormProvider, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import {
  validationSchema,
  vehicleSearchInitialValues,
} from 'components/flexFlow/vehicle/vehicleSearch/vehicleSearchUtils';
import { VehicleSearchValues } from 'components/flexFlow/vehicle/VehicleTypes';
import { useContext, useMemo } from 'react';
import { transformVehicleListFromApiData } from 'components/shared/uiModels/vehicle/vehicleTransformer';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import NetworkError from 'components/shared/errors/NetworkError';
import { VehicleListContext } from 'context/vehicleList/VehicleListContext';
import { Box } from '@mui/material';

export const VehiclePage: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const hasLocationData = useAppSelector(selectHasLocationData);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<VehicleSearchValues>({ resolver: resolver, defaultValues: vehicleSearchInitialValues });
  const isModifyScreen = useAppSelector(selectIsModifyFlow);
  const { displayVehicleList, toggleVehicleList } = useContext(VehicleListContext);
  const showVehicleContent = displayVehicleList || !isModifyScreen;
  const {
    data: vehicleContent,
    isFetching: isVehicleContentLoading,
    isError: vehicleContentError,
  } = useVehicleContentQuery();
  const { vehicleRates, isLoading: isVehicleRatesLoading } = useVehicleRates();

  const vehicles = useMemo(() => {
    if (!(isVehicleContentLoading || isVehicleRatesLoading)) {
      return transformVehicleListFromApiData(vehicleContent, vehicleRates);
    }
    return [];
  }, [isVehicleContentLoading, isVehicleRatesLoading, vehicleContent, vehicleRates]);

  return (
    <Box sx={{ height: '0px', width: '100%', flexGrow: '1' }}>
      <FormProvider {...methods}>
        <FlexFlowCard sx={{ height: 'auto', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
          <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
          <Box id={'vehicle-page-content'} sx={{ height: 'auto', overflow: 'scroll' }}>
            <VehicleInfo vehicles={vehicles} />
            {!isResViewOnly && (
              <>
                {hasLocationData && (
                  <>
                    <DividerWithFullWidth
                      style={{
                        borderColor: ehiTheme.palette.divider,
                        borderBottomWidth: 1,
                        margin: ehiTheme.spacing(0),
                      }}
                    />
                    <VehicleSearchContainer data-testid={'vehicleSearch'}>
                      <VehicleSearchForm
                        vehicles={vehicles}
                        isContentVisible={showVehicleContent}
                        onToggleContent={(): void => toggleVehicleList()}
                      />
                    </VehicleSearchContainer>
                  </>
                )}
                <DividerWithFullWidth
                  style={{ borderColor: ehiTheme.palette.divider, borderBottomWidth: 3, margin: ehiTheme.spacing(0) }}
                />
                {showVehicleContent && <VehicleList vehicles={vehicles} />}
              </>
            )}
          </Box>
        </FlexFlowCard>
        <ProgressOverlay inProgress={isVehicleContentLoading || isVehicleRatesLoading} />
        {vehicleContentError && <NetworkError />}
      </FormProvider>
    </Box>
  );
};
