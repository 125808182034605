import { useCallback, useMemo, useState } from 'react';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { Box, CardActions } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';
import { useTranslations } from 'components/shared/i18n';
import { useGetRenterProfile } from 'services/renter/useGetRenterProfile';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow, selectRenter } from 'redux/selectors/bookingEditor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddDriverDialog } from 'components/flexFlow/driver/addDriver/AddDriverDialog';
import { QuickDriverDialog } from 'components/flexFlow/driver/additionalDriver/QuickDriverDialog';
import { StyledButtonGroup, StyledEhiButton } from 'components/flexFlow/driver/Driver.styles';
import { ProgressOverlay } from 'components/shared/ui/spinner/ProgressOverlay';
import NetworkError from 'components/shared/errors/NetworkError';
import { Action } from 'components/shared/ui/card/CardTypes';
import { useGetAdditionalDriverProfiles } from 'services/renter/useGetAdditionalDriverProfiles';
import { DriverCard } from 'components/flexFlow/driver/DriverCard';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashPaths } from 'app/router/RouterPaths';
import { EMPTY_VALUE } from 'utils/constants';
import { EditDriverDialog } from 'components/flexFlow/driver/editDriver/EditDriverDialog';
import { DriverData } from 'components/shared/uiModels/driver/driverDataTypes';

export const Driver: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  const isReadOnlyFlow = useAppSelector(selectIsReadOnlyFlow);
  const primaryDriver = useAppSelector(selectRenter);
  const [editDriverOpen, setEditDriverOpen] = useState(false);
  const [additionalDriverData, SetAdditionalDriverData] = useState<DriverData | undefined>(undefined);
  const [quickAddDriverOpen, setQuickAddDriverOpen] = useState<boolean>(false);
  const { driverData, isRenterProfileLoading, isRenterProfileError, refetchDriverProfile } = useGetRenterProfile();
  const navigate = useNavigate();
  const [changePrimaryDriverDialogIsOpen, setChangePrimaryDriverDialogIsOpen] = useState(false);

  const { hash } = useLocation();
  const {
    data: additionalDriverProfiles,
    additionalDriversFetching,
    additionalDriversErrors,
    refetchAdditionalDrivers,
  } = useGetAdditionalDriverProfiles();
  const { data: phoneTypeDomain, isFetching: isPhoneDomainLoading, isError: isPhoneDomainError } = usePhoneTypesQuery();
  const primaryDriverActions: Action[] = useMemo(() => {
    if (!(driverData && driverData?.lastName)) {
      return [
        {
          label: t('driverSearch.add'),
          testId: 'add-driver',
          onClick: () => navigate({ hash: HashPaths.AddDriver }, { replace: true }),
          color: 'primary',
        },
      ];
    } else if (!isReadOnlyFlow) {
      return [
        {
          label: t('common.edit'),
          onClick: () => setEditDriverOpen(true),
          testId: 'edit-driver',
          color: 'primary',
        },
      ];
    }

    return [];
  }, [driverData, isReadOnlyFlow, navigate, t]);

  const additionalDriverActions = useCallback(
    (driverData: DriverData, index: number): Action[] => {
      if (!isReadOnlyFlow) {
        return [
          {
            label: t('common.edit'),
            onClick: (): void => {
              setEditDriverOpen(true);
              SetAdditionalDriverData({ ...driverData, additionalDriverIndex: index });
            },
            testId: 'edit-additional-driver',
            color: 'primary',
          },
        ];
      }

      return [];
    },
    [isReadOnlyFlow, t]
  );

  const handleClick: () => void = () => {
    navigate({ hash: HashPaths.AddDriver }, { replace: true });
  };

  const handleToggle = (): void => {
    setQuickAddDriverOpen(true);
  };

  useEffectOnlyOnce(() => {
    // Show Search/Create Driver Modal if no driver present
    if (!primaryDriver) {
      navigate({ hash: HashPaths.AddDriver }, { replace: true });
    }
  });

  function getAddDriverDialogTitle(): string {
    if (hash === HashPaths.ReplaceDriver) return t('driver.changePrimaryDriver');
    return primaryDriver ? t('driver.additionalDrivers.title') : t('driver.addPrimaryDriver');
  }

  return (
    <Box sx={{ height: '0px', width: '100%', flexGrow: '1' }}>
      <FlexFlowCard
        data-testid={'driverFlexFlowCard'}
        sx={{ height: 'auto', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <Box
          sx={{
            flexGrow: 1,
            height: 'auto',
            overflow: 'scroll',
            padding: ehiTheme.spacing(3),
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: ehiTheme.spacing(3) }}>
            <FlexiFlowCardInnerContainer
              sx={{
                margin: ehiTheme.spacing(0),
                padding: ehiTheme.spacing(3),
                width: '100%',
                boxSizing: 'border-box',
              }}
              title={t('driver.primaryDriver')}
              data-testid={`primary-driver`}
              headerActions={primaryDriverActions}>
              {driverData && <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} />}
            </FlexiFlowCardInnerContainer>
            {additionalDriverProfiles?.map((driverData, index) => (
              <FlexiFlowCardInnerContainer
                key={`additional-driver-${index}`}
                data-testid={`additional-driver-${index}`}
                title={t('driver.additionalDrivers.additionalDrivers')}
                headerActions={additionalDriverActions(driverData, index)}
                sx={{
                  margin: ehiTheme.spacing(0),
                  padding: ehiTheme.spacing(3),
                  width: '100%',
                  boxSizing: 'border-box',
                }}>
                <DriverCard driver={driverData} phoneTypeDomain={phoneTypeDomain} isAdditionalDriver />
              </FlexiFlowCardInnerContainer>
            ))}
            {!isReadOnlyFlow && driverData && driverData?.lastName && additionalDriverProfiles?.length < 9 && (
              <FlexiFlowCardInnerContainer
                sx={{
                  border: 'none',
                  borderRadius: ehiTheme.spacing(0),
                  padding: ehiTheme.spacing(0),
                  margin: ehiTheme.spacing(0),
                  width: '100%',
                  boxSizing: 'border-box',
                }}>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: 0 }}>
                  <div>
                    <StyledButtonGroup variant='contained' aria-label='split button'>
                      <StyledEhiButton
                        onClick={handleClick}
                        primary
                        data-testid='add-driver-button'
                        sx={{ margin: ehiTheme.spacing(0) }}>
                        <span>{t('driver.addDriverTitle')}</span>
                      </StyledEhiButton>
                      <StyledEhiButton
                        sx={{ margin: ehiTheme.spacing(0) }}
                        size='small'
                        aria-label='quick add driver'
                        aria-haspopup='menu'
                        onClick={handleToggle}
                        primary
                        data-testid='quick-add-driver-button'>
                        <ArrowDropDownIcon />
                      </StyledEhiButton>
                    </StyledButtonGroup>
                  </div>
                </CardActions>
              </FlexiFlowCardInnerContainer>
            )}
          </Box>
        </Box>

        <AddDriverDialog
          open={hash === HashPaths.AddDriver || hash === HashPaths.ReplaceDriver}
          onClose={(): void => navigate({ hash: EMPTY_VALUE })}
          title={getAddDriverDialogTitle()}
          data-testid='add-driver-dialog'
          isUpdatingProfile={hash === HashPaths.ReplaceDriver}
          changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
          setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
        />

        {quickAddDriverOpen && (
          <QuickDriverDialog
            open={quickAddDriverOpen}
            onClose={(): void => setQuickAddDriverOpen(false)}
            driverCount={additionalDriverProfiles.length}
            data-testid='quick-add-driver-dialog'
          />
        )}
        <EditDriverDialog
          isAdditionalDriver={!!additionalDriverData}
          driver={additionalDriverData ?? driverData}
          open={editDriverOpen}
          onClose={(driverModified): void => {
            if (driverModified) {
              additionalDriverData ? refetchAdditionalDrivers() : refetchDriverProfile();
            }
            setEditDriverOpen(false);
            SetAdditionalDriverData(undefined);
          }}
          changePrimaryDriverDialogIsOpen={changePrimaryDriverDialogIsOpen}
          setChangePrimaryDriverDialogIsOpen={setChangePrimaryDriverDialogIsOpen}
        />
        <ProgressOverlay inProgress={isRenterProfileLoading || isPhoneDomainLoading || additionalDriversFetching} />
        {(isRenterProfileError || isPhoneDomainError || additionalDriversErrors) && <NetworkError />}
      </FlexFlowCard>
    </Box>
  );
};
