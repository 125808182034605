import { FC, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FlexiFlowMenu } from 'components/shared/flexiFlow/FlexiFlowMenu';
import { InlineReservationSummary } from 'components/resSummary/InlineReservationSummary';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { FlexiFlowRouterProps } from './FlexFlowTypes';
import { Box } from '@mui/material';
import { ehiTheme } from '@ehi/ui';
import { ReservationAlerts } from 'components/shared/reservationAlerts/ReservationAlerts';
import { useAppSelector } from 'redux/hooks';
import { selectIsReadOnlyFlow } from 'redux/selectors/bookingEditor';

export const FlexMenuWrapper: FC<FlexiFlowRouterProps> = ({ pages }) => {
  const { openDrawer } = useContext(ResSummaryDrawerContext);
  const isResViewOnly = useAppSelector(selectIsReadOnlyFlow);

  return (
    <Box display={'flex'} width={'100%'} height={'100%'}>
      {openDrawer && <InlineReservationSummary />}
      <Box display={'flex'} flexGrow={'1'} flexDirection={'column'} width={'0'} alignItems={'center'}>
        <Box
          display={'flex'}
          boxSizing={'border-box'}
          flexDirection={'column'}
          gap={ehiTheme.spacing(2)}
          flexGrow={1}
          padding={ehiTheme.spacing(1.25, 3, 3)}
          alignItems={'center'}
          // Extra bottom margin added to ensure users can get to information on
          // screen without being blocked by floating button (so they can scroll more)
          marginBottom={isResViewOnly ? ehiTheme.spacing(0) : ehiTheme.spacing(4)}
          width={'100%'}
          maxWidth={'1920px'}>
          <FlexiFlowMenu pages={pages} />
          <ReservationAlerts />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
