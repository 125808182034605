import { AppBar, Box, Link, List, ListItemText, Radio, styled } from '@mui/material';
import { EhiButton, ehiTheme } from '@ehi/ui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// #FFFFFF
export const contentContainerBackground = ehiTheme.palette.background.paper;
// #F5F5F5
export const pageContainerBackground = ehiTheme.palette.background.default;

export const primaryText = ehiTheme.palette.text.primary;
export const secondaryText = ehiTheme.palette.text.secondary;

export const BoxShadow = styled(Box)`
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: ${ehiTheme.spacing(2)};
`;

export const DefaultPageContainer = styled(Box)`
  color: ${primaryText};
  background-color: ${pageContainerBackground};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FullHeightContainer = styled(Box)`
  color: ${primaryText};
  background-color: ${pageContainerBackground};
  width: 100vw;
  min-height: 100%;
`;

export const GridAreaContainer = styled(Box)`
  display: grid;
  grid-template-areas: '. center .';
  grid-template-columns: 1fr 760px 1fr;
  @media (max-width: 800px) {
    grid-template-columns: 1fr 18fr 1fr;
  }
`;

export const PageContainer = styled(Box)`
  display: grid;
  place-items: center;
  height: 100%;
`;

export const BulletedList = styled(List)`
  list-style-type: disc;
  padding-left: ${ehiTheme.spacing(3)};
  color: ${(props): string => props.theme.palette.text.secondary};
`;

export const BulletedItem = styled(ListItemText)`
  padding-left: ${ehiTheme.spacing(1)};
  display: list-item;
`;

export const TabBar = styled(AppBar)`
  && {
    box-shadow: none;
    padding: ${(props): string => props.theme.spacing(0, 2)};
    background-color: transparent;
  }
`;

export const PrimaryButton = styled(EhiButton)``;
PrimaryButton.defaultProps = {
  primary: true,
};

export const PaddedDiv = styled('div')`
  padding: ${ehiTheme.spacing(2)};
  height: calc(100vh - 64px);
  overflow: auto;
`;

export const HomePageContainer = styled(Box)`
  grid-area: center;
  display: flex;
  flex-direction: column;
  padding-bottom: ${ehiTheme.spacing(3)};
`;

export const MarginWrapper = styled('div')`
  margin: ${ehiTheme.spacing(1, 2)};
`;

export const IFrameContainer = styled(DefaultPageContainer)`
  height: 100vh;
`;

export const StyledList = styled(List)`
  width: 100%;
  margin: 0;
  padding: 0;
`;

export const RadioButton = styled(Radio)`
  color: #4d789d;

  &.Mui-checked {
    color: white;
  }
`;

export const ExpandMoreButton = styled(ExpandMore)`
  color: #4d789d;

  &.isSelected {
    color: white;
  }
`;

export const ExpandLessButton = styled(ExpandLess)`
  color: #4d789d;

  &.isSelected {
    color: white;
  }
`;

export const StyledLink = styled(Link)`
  color: #4d789d;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
  margin-left: 0;
  padding: 0;
`;
