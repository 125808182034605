import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { LoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewTypes';
import { useTranslations } from 'components/shared/i18n';
import { Box } from '@mui/material';
import { PoliciesSection } from 'components/flexFlow/review/sections/PoliciesSection';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { useContext, useEffect } from 'react';
import { ResSummaryDrawerContext } from 'context/resSummary/ResSummaryDrawerContext';
import { VehicleSection } from 'components/flexFlow/review/sections/VehicleSection';
import { EstimatedCharges } from 'components/flexFlow/review/sections/EstimatedCharges';
import { WhenAndWhereSection } from 'components/flexFlow/review/sections/WhenAndWhereSection';
import { ehiTheme } from '@ehi/ui';

export const Review: FlexiFunctionComponent = ({ onPrevious }) => {
  const { t } = useTranslations();
  const { openDrawer, setOpenDrawer } = useContext(ResSummaryDrawerContext);

  useEffect(() => {
    if (openDrawer) {
      setOpenDrawer(false);
    }
  }, [openDrawer, setOpenDrawer]);

  return (
    <Box sx={{ height: '0px', width: '100%', flexGrow: '1' }}>
      <FlexFlowCard
        loadingState={LoadingState.SUCCESS}
        sx={{ height: 'auto', maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <StickyCardNavigation onPrevious={onPrevious} showExitToRentalSummary={false} isReviewPage={true} />
        <FlexiFlowCardInnerContainer
          data-testid='reviewPage'
          title={t('review.overView')}
          sx={{
            border: 0,
            margin: ehiTheme.spacing(0),
            padding: ehiTheme.spacing(3),
            height: 'auto',
            maxHeight: '100%',
            overflow: 'scroll',
          }}>
          <WhenAndWhereSection />
          <VehicleSection />
          <EhiDivider />
          <PoliciesSection />
          <EhiDivider />
          <EstimatedCharges />
        </FlexiFlowCardInnerContainer>
      </FlexFlowCard>
    </Box>
  );
};
