import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexFlowTypes';
import { FlexFlowCard, FlexiFlowCardInnerContainer } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import { BillToSection } from 'components/flexFlow/rateAndBilling/BillToSection';
import { mapLoadingState } from 'components/shared/ui/spinner/loadableView/LoadableViewUtils';
import { RateSourceSection } from 'components/flexFlow/rateAndBilling/RateSourceSection';
import { EhiDivider } from 'components/shared/ui/styles/Divider.styles';
import { Box } from '@mui/material';

export const RateAndBilling: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <Box sx={{ height: '0px', width: '100%', flexGrow: '1' }}>
      <FlexFlowCard
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        loadingState={mapLoadingState(false, false)}>
        <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
        <FlexiFlowCardInnerContainer
          style={{
            padding: 0,
            margin: 0,
            border: 'none',
            boxShadow: 'none',
            height: '0',
            flexGrow: '1',
            overflow: 'scroll',
          }}>
          <RateSourceSection />
          <EhiDivider />
          <BillToSection />
        </FlexiFlowCardInnerContainer>
      </FlexFlowCard>
    </Box>
  );
};
